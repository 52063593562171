<template>
  <div v-if="!isEtbcTenant" class="login-container">
    <div class="box-form">
      <img
        v-if="isSzrqTenant"
        class="logo-szrq"
        src="@/assets/images/icon/login-logo-szrq.png"
      />
      <img v-else class="logo" src="@/assets/images/icon/login-logo.png" />
      <h2 v-if="!isSzrqTenant" class="title">IoT Monitoring Platform</h2>
      <el-form
        ref="loginForm"
        class="login-form"
        :class="{ pdt: isSzrqTenant }"
        :model="loginForm"
        :rules="loginFormRules"
      >
        <el-form-item prop="account">
          <el-input v-model="loginForm.account" placeholder="请填写用户名">
            <img
              slot="prepend"
              class="icon-prepend"
              src="@/assets/images/icon/login-user.png"
            />
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            ref="password"
            v-model="loginForm.password"
            placeholder="请填写密码"
            :type="passwordType"
            @input="handlePwdChange"
            @change="handlePwdChange"
            @blur="handlePwdChange"
          >
            <img
              slot="prepend"
              class="icon-prepend"
              src="@/assets/images/icon/login-lock.png"
            />
            <div slot="suffix">
              <i
                class="iconfont pointer"
                :class="
                  passwordType === 'password' ? 'icon-biyan' : 'icon-zhengyan'
                "
                @click="handlePwdIcon"
              ></i>
            </div>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input
            class="code-input"
            placeholder="请填写验证码"
            v-model="loginForm.code"
            @keyup.enter.native.stop="handleLogin"
          >
            <img
              slot="prepend"
              class="icon-prepend code"
              src="@/assets/images/icon/login-code.png"
            />
            <template slot="append">
              <img
                class="code-img pointer"
                alt="验证码"
                title="点击刷新验证码"
                :src="codeImg"
                @click="handleCodeImg"
              />
            </template>
          </el-input>
        </el-form-item>
        <el-button
          class="login-btn"
          :disabled="loginDisabled"
          :loading="loading"
          @click.native.prevent="handleLogin"
        >
          {{ btnText }}
        </el-button>
      </el-form>
    </div>
    <div v-if="!isSzrqTenant" class="copyright">
      Copyright © 2022 金卡智能 All Rights Reserved ｜ 浙ICP备05008091号-10
    </div>
    <modify-pwd-dialog
      :show.sync="showModifyDialog"
      :showWarningTip="true"
      :loginInfo="loginInfo"
      @change-success="handlePwdChangeSuccess"
    />
  </div>
</template>
<script>
import { apiCodeImg, apiLogin, apiEtbcLogin } from "@/api/login";
import { isEtbcTenant, etbcLogout, isSzrqTenant } from "@/utils";
import { ruleRequired, ruleMaxLength } from "@/utils/rules";
import { mapActions, mapGetters } from "vuex";
import { clearLS } from "@/utils/storage";
import ModifyPwdDialog from "@/layout/components/Header/ModifyPwdDialog.vue";

export default {
  name: "Login",
  components: { ModifyPwdDialog },
  data() {
    return {
      isEtbcTenant,
      isSzrqTenant,
      loginForm: {
        account: "",
        password: "",
        code: "",
        codeKey: "",
      },
      loginFormRules: {
        account: [ruleRequired("账号不能为空"), ruleMaxLength(32)],
        password: [ruleRequired("密码不能为空"), ruleMaxLength(18)],
        code: [ruleRequired("验证码不能为空"), ruleMaxLength(4)],
      },
      passwordType: "password",
      codeImg: "", // 登录验证码
      btnText: "立即登录",
      loading: false,
      timer: null,
      loginDisabled: false,
      showModifyDialog: false, // 是否显示修改密码弹框
      loginInfo: {}, //修改密码
    };
  },
  created() {
    clearLS();
    if (this.isEtbcTenant) {
      this.etbcLogin();
    } else {
      this.handleCodeImg();
    }
  },
  methods: {
    ...mapActions({
      login: "user/login",
      generateRoutes: "app/generateRoutes",
      getDataList: "common/getDataList",
    }),
    etbcLogin() {
      this.login({ apiFn: apiEtbcLogin })
        .then((obj) => {
          this.loginSuccess(obj.result);
        })
        .catch(() => {
          etbcLogout();
        });
    },
    // 获取图形码
    handleCodeImg() {
      apiCodeImg({
        color: "97,116,255",
        from: "215,230,250",
        to: "215,230,250",
        // height: 44,
        // width: 100,
        // size: 32,
      })
        .then((res) => {
          this.codeImg = res.codeImage;
          this.loginForm.codeKey = res.codeKey;
        })
        .catch((res) => {
          this.$message.error("获取验证码失败！");
          console.warn(res);
        });
    },
    // 密码不支持汉字，去除汉字
    handlePwdChange() {
      this.loginForm.password = this.loginForm.password.replace(
        /[\u4e00-\u9fa5]/g,
        ""
      );
    },
    // 点击密码图标控制密码显隐
    handlePwdIcon() {
      this.passwordType === "password"
        ? (this.passwordType = "")
        : (this.passwordType = "password");
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    // 登录
    handleLogin() {
      if (this.loginDisabled) return;
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.loginDisabled = true;
        this.login({ apiFn: apiLogin, params: this.loginForm })
          .then((obj) => {
            if (obj.flag) {
              this.loginSuccess(obj.result);
            } else {
              this.showModifyDialog = true;
              this.loginDisabled = false;
              this.loginInfo = { ...obj.result };
            }
          })
          .catch((err) => this.loginFail(err))
          .finally(() => {
            this.loading = false;
          });
      });
    },
    // 登录成功
    loginSuccess() {
      this.loginDisabled = false;
      this.btnText = "立即登录";
      // 根据权限映射路由表
      this.generateRoutes().then(() => {
        if (this.routes?.length) {
          const { fullPath } = this.getFirstChild(this.routes);
          this.$router.push(fullPath);
        } else {
          this.$notify("暂无项目访问权限，请联系管理员");
        }
      });
      // 获取数据字典
      this.getDataList();
    },
    getFirstChild(list) {
      if (list[0].children?.length) return this.getFirstChild(list[0].children);
      return list[0];
    },
    // 登录失败
    loginFail(err) {
      if (err?.code === "10117") {
        let count = 60;
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          if (count < 1) {
            this.btnText = "立即登录";
            this.loginDisabled = false;
            clearInterval(this.timer);
          } else {
            this.btnText = count-- + "s后重试";
          }
        }, 1000);
      } else {
        this.loginDisabled = false;
        this.$message.error(err.message || "登录失败-调用接口失败");
      }
      this.handleCodeImg();
    },
    handlePwdChangeSuccess() {
      location.reload();
    },
  },
  beforeDestroy() {
    this.timer = null;
  },
  computed: {
    ...mapGetters({
      routes: "app/routes",
    }),
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: url("~@/assets/images/bg/bg-login.png") no-repeat;
  background-color: #4b8af3;
  background-size: cover;
  background-position: center;
  .box-form {
    position: absolute;
    width: 440px;
    top: 50%;
    right: 14%;
    transform: translateY(-50%);
    background: rgba(0, 17, 102, 0.29);
    border-radius: 20px;

    .logo {
      height: 64px;
      display: block;
      margin: 60px auto 0;
    }

    .logo-szrq {
      height: 180px;
      display: block;
      margin: 30px auto 0;
    }

    .title {
      margin-top: 18px;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      text-align: center;
    }

    .login-form {
      padding: 60px 40px 70px;
      &.pdt {
        padding-top: 20px;
      }
      ::v-deep {
        .el-form-item {
          margin-bottom: 30px;
          border: none;
          border-radius: 8px;
          &:focus-within {
            border-color: #4b8af3;
          }
          &__error {
            color: $base-color-error;
            margin-top: 4px;
          }
          &.is-error {
            margin-bottom: 30px;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-transition-delay: 99999s;
            -webkit-transition: color 99999s ease-out,
              background-color 99999s ease-out;
          }
          .el-input__inner {
            padding: 0 10px;
            height: 44px;
            border: none;
            color: #666;
            line-height: 24px;

            &::placeholder {
              color: #d3dae6;
            }
          }

          .icon {
            font-size: 18px;
            line-height: 36px;
          }
          .iconfont {
            line-height: 44px;
            font-size: 28px;
            margin-right: 8px;
          }
        }

        .el-input-group__prepend {
          background: #fff;
          border: none;
          padding: 0 2px 0 20px;
          min-width: 40px;
          .icon-prepend {
            width: 18px;
            height: 18px;
            position: relative;
            top: 2px;
            &.code {
              margin-top: 13px;
            }
          }
        }

        .el-input.code-input {
          display: flex;
          .el-input-group__append {
            padding: 0;
            flex: 0 0 100px;
            width: 100px;
            border: none;
            overflow: hidden;
            box-sizing: border-box;
            background-color: rgba(215, 230, 250, 1);
            font-size: 0 !important;
          }
          .code-img {
            margin-top: 0;
            display: inline-block;
            width: 100%;
            height: 44px;
            object-fit: contain !important;
            overflow: hidden;
          }
        }
      }
      .el-button.login-btn {
        margin-top: 10px;
        width: 100%;
        height: 44px;
        background: #5975ff;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        color: #fff;
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
  @media screen and (max-width: 800px) {
    .box-form {
      width: 90%;
      left: 50%;
      right: none;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
